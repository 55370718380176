import React from "react";

type Props = {
  name: string;
  video: string;
  desc: string;
};

export const Card: React.FC<Props> = ({ name, video, desc }) => {
  const handleOnMouseOver = (e: React.MouseEvent<HTMLVideoElement>) =>
    e.currentTarget.play();
  const handleOnMouseOut = (e: React.MouseEvent<HTMLVideoElement>) =>
    e.currentTarget.pause();

  return (
    <>
      <div className="relative rounded-lg card md:h-[35vh] lg:h-auto 2xl:h-[35vh] 3xl:h-[40vh] relative overflow-hidden ">
        <div className="absolute bg-black/50 h-full w-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"></div>
        <div className="absolute text-center text-white w-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ">
          <h1 className="text-2xl 2xl:text-3xl 3xl:text-4xl font-bold p-3 ">
            {name}
          </h1>

          <p className="text-gray-100 px-10 3xl:px-24 text-sm 2xl:text-lg 3xl:text-xl">
            {desc}
          </p>
        </div>

        <video
          className="h-auto w-full"
          src={video}
          loop
          muted
          autoPlay
          playsInline
          // onMouseOver={handleOnMouseOver}
          // onMouseOut={handleOnMouseOut}
        ></video>
      </div>
    </>
  );
};
