import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n, { changeLanguage } from "i18next";

export default function ChangeLanguage() {
  const [RUselected, isRUSelected] = useState(true);
  const [ENselected, isENSelected] = useState(false);
  const ruBtnClassName = RUselected
    ? `rounded border bg-[radial-gradient(ellipse_at_top_right,_var(--tw-gradient-stops))] from-lime-lighter via-transparent to-transparent`
    : " ";
  const ruBtnClasses = ["w-11 p-[2px]", ruBtnClassName];

  const enBtnClassName = ENselected
    ? `rounded border bg-[radial-gradient(ellipse_at_top_right,_var(--tw-gradient-stops))] from-lime-lighter via-transparent to-transparent`
    : " ";
  const enBtnClasses = ["w-11 p-[2px]", enBtnClassName];
  const changeLanguageToRU = () => {
    i18n.changeLanguage("ru");
    if (i18n.language == "ru") {
      isRUSelected(true);
      isENSelected(false);
    }
  };

  const changeLanguageToEN = () => {
    i18n.changeLanguage("en");
    if (i18n.language == "en") {
      isRUSelected(false);
      isENSelected(true);
    }
  };
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (i18n.language == "ru") {
      isRUSelected(true);
      isENSelected(false);
    }
    if (i18n.language == "en") {
      isRUSelected(false);
      isENSelected(true);
    }
  }, [RUselected, ENselected])
  return (
    <>
      <div className="flex p-2 rounded space-x-2">
        <button className={ruBtnClasses.join(" ")} onClick={changeLanguageToRU}>
          {t("changeLanguageButtonRU")}
        </button>
        <button className={enBtnClasses.join(" ")} onClick={changeLanguageToEN}>
          {t("changeLanguageButtonEN")}
        </button>
      </div>
    </>
  );
}
