import React from "react";
import { useTranslation } from "react-i18next";
export default function Footer2() {
  const { t, i18n } = useTranslation();
  return (
    <div className="bg-main-gray z-30 relative text-white">
      <footer className="mx-auto 2xl:text-base 3xl:text-xl">
        <div className=" px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 flex flex-col items-center justify-between gap-10 border-t border-b border-lime-lighter py-6 lg:flex-row">
          <div className=" flex flex-col gap-4 lg:items-center justify-center gap-x-7 md:gap-x-10 lg:gap-x-10 md:justify-start sm:flex-row">
            <div className="flex inline-flex items-center ">
              <a href="https://maps.app.goo.gl/AujJeR9ZsuZQBg2m6" className="">
                <button className="relative flex self-center w-12 h-12 3xl:w-16 3xl:h-16 mr-5 md:mr-2 xl:mr-4 items-center rounded-full border-2 border-lime !text-lime text-sm 2xl:text-xl font-semibold justify-center overflow-hidden bg-transparent hover:!text-main-gray shadow-2xl transition-all before:absolute before:h-0 before:w-0 before:rounded-full before:bg-lime before:duration-500 before:ease-out hover:shadow-lime-lighter hover:before:h-56 hover:before:w-56">
                  <i className="z-10 fa-solid fa-map-location-dot"></i>
                </button>
              </a>

              <div className="flex flex-col ">
                <p className="">{t("footerAdressDesc")}</p>
                <p className="text-gray-light">
                {t("footerAdress")}
                </p>
              </div>
            </div>

            <div className="flex inline-flex items-center">
              <a href="mailto:artwallinfo@gmail.com">
                <button className="relative flex self-center w-12 h-12 3xl:w-16 3xl:h-16 mr-5 md:mr-2 xl:mr-4 items-center rounded-full border-2 border-lime !text-lime text-sm 2xl:text-xl font-semibold justify-center overflow-hidden bg-transparent hover:!text-main-gray shadow-2xl transition-all before:absolute before:h-0 before:w-0 before:rounded-full before:bg-lime before:duration-500 before:ease-out hover:shadow-lime-lighter hover:before:h-56 hover:before:w-56">
                  <i className="z-10 fa-solid fa-at"></i>
                </button>
              </a>
              <div className="flex flex-col">
                <p className="">EMAIL</p>
                <p className="text-gray-light">artwallinfo@gmail.com</p>
              </div>
            </div>
          </div>

          <div className="flex gap-7 lg:gap-5 2xl:gap-8 3xl:gap-10">
            <a
              href="https://t.me/damir_artwall"
              target="_blank"
              className="transition duration-100 text-5xl 3xl:text-6xl hover:text-gray-500 active:text-gray-600"
            >
              <i className="text-lime hover:text-lime-dark fa-brands fa-telegram"></i>
            </a>

            <a
              href="https://vk.com/artwallmedia"
              target="_blank"
              className="transition duration-100 text-5xl 3xl:text-6xl hover:text-gray-500 active:text-gray-600"
            >
              <i className="text-lime hover:text-lime-dark  fa-brands fa-vk"></i>
            </a>

            <a
              href="https://www.instagram.com/artwallufa"
              target="_blank"
              className="transition duration-100 text-5xl 3xl:text-6xl hover:text-gray-500 active:text-gray-600"
            >
              <i className="text-lime hover:text-lime-dark fa-brands fa-square-instagram"></i>
            </a>

            <a
              href="https://www.youtube.com/@artwallstudio2014"
              target="_blank"
              className="transition duration-100 text-5xl 3xl:text-6xl hover:text-gray-500 active:text-gray-600"
            >
              <i className="text-lime hover:text-lime-dark fa-brands fa-youtube"></i>
            </a>
          </div>
        </div>

        <div className="py-8 px-5 xl:px-24 lg:px-5 md:px-7 sm:px-10 text-center text-sm 3xl:text-lg text-gray-light">
        {t("footerDesc")}
        </div>
      </footer>
    </div>
  );
}
