import React, { useState } from "react";
import type { FieldValues } from "react-hook-form";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../firebase";
import { serverTimestamp } from "firebase/firestore";
import Footer2 from "../../Footer2";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";

const phoneRegex = new RegExp(
  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
);

const schema = z.object({
  firstName: z.string().nonempty({ message: 'emptyError'}),
  email: z
    .string()
    .nonempty({ message: 'emptyError'})
    .email({ message: 'emailError'}),
  phone: z
    .string()
    .nonempty({ message: 'emptyError'})
    .regex(phoneRegex, { message: 'numberError'}),
  message: z.string(),
});

type TSchema = z.infer<typeof schema>;

export default function FormContact() {
  
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    getValues,
  } = useForm<TSchema>({
    resolver: zodResolver(schema),
  });
  const [send, isSend] = useState(false);

  const onSubmit = async (data: FieldValues) => {
    const TOKEN = process.env.REACT_APP_TOKEN;
    const CHAT_ID = "-1001981697679";
    const URI_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;
    let message = `<b>Заявка с сайта</b>\n`;

    message += `<b>Имя: </b> ${getValues("firstName")}\n`;
    message += `<b>Телефон: </b> ${getValues("phone")}\n`;
    message += `<b>Email: </b> ${getValues("email")}\n`;
    message += `<b>Сообщение: </b> ${getValues("message")}\n`;

    const clientsRef = collection(db, "clients");

    const onSubmitFB = async () => {
      try {
        await addDoc(clientsRef, {
          date: serverTimestamp(),
          name: getValues("firstName"),
          phone: getValues("phone"),
          email: getValues("email"),
          message: getValues("message"),
        });
      } catch (err) {
        console.log(err);
      }
    };
    const Sender = async () => {
      // if(nameError == '' && numberError == '' && emailError == ''){ {
      try {
        await axios
          .post(URI_API, {
            chat_id: CHAT_ID,

            parse_mode: "html",

            text: message,
          })
          .then(({ data }) => {
            //   return data;
            isSend(true);
          });
      } catch (e) {
        console.log("Sending error", e);
      }
    };
    Sender();
    onSubmitFB();
    reset();
  };

  const nameClassName = errors.firstName
    ? "border-error"
    : "border-lime-lighter";
  const nameClasses = [
    "w-full  bg-main-gray border-b-2  px-3 py-2 outline-none focus:bg-main-gray transition duration-100 focus:border-lime",
    nameClassName,
  ];

  const phoneClassName = errors.phone ? "border-error" : "border-lime-lighter";
  const phoneClasses = [
    "w-full  bg-main-gray border-b-2 px-3 py-2 outline-none focus:bg-main-gray transition duration-100 focus:border-lime",
    phoneClassName,
  ];

  const emailClassName = errors.phone ? "border-error" : "border-lime-lighter";
  const emailClasses = [
    "w-full  bg-main-gray border-b-2 px-3 py-2 outline-none focus:bg-main-gray transition duration-100 focus:border-lime",
    emailClassName,
  ];
  const { t, i18n } = useTranslation();
  return (
    <>
      <section
        id="form"
        className="bg-main-gray  !z-40 relative text-white border-t border-lime-lighter"
      >
        <h3 className="px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 text-3xl 2xl:text-4xl 3xl:text-5xl font-bold  py-10 3xl:py-16">
          {t("getInTouch")}
        </h3>

        <div className="px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 flex flex-col justify-between gap-16">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="2xl:text-lg 3xl:text-xl max-w-screen-sm gap-5"
          >
            {send && (
              <>
                {" "}
                <div className="mb-3">
                  <span className="text-sm 2xl:text-lg 3xl:text-xl text-lime">
                    {t("successSend")}
                  </span>
                </div>
              </>
            )}
            <div className="mb-3">
              <input
                {...register("firstName")}
                placeholder={t("formName")}
                name="firstName"
                className={nameClasses.join(" ")}
              />
              {errors.firstName && (
                <p className="text-error pt-1">{t(`${errors.firstName.message}`)}</p>
              )}
            </div>

            <div className="mb-3">
              <input
                {...register("phone")}
                placeholder={t("formPhone")}
                name="phone"
                className={phoneClasses.join(" ")}
              />
              {errors.phone && (
                <p className="text-error pt-1">{t(`${errors.phone.message}`)}</p>
              )}
            </div>

            <div className="mb-3">
              <input
                {...register("email")}
                placeholder="Email*"
                name="email"
                className={emailClasses.join(" ")}
              />
              {errors.email && (
                <p className="text-error pt-1">{t(`${errors.email.message}`)}</p>
              )}
            </div>

            <div className="mb-3">
              <textarea
                {...register("message")}
                placeholder={t("formMessage")}
                name="message"
                className="w-full bg-main-gray border-b-2 border-lime-lighter px-3 py-2 outline-none min-h-[10vh] transition duration-100 focus:border-lime"
              ></textarea>
            </div>
            <div className="mb-3">
              <span className="text-sm 2xl:text-base text-gray-light">
                {t("personalData")}
              </span>
            </div>

            <div className="flex items-center mt-5 justify-between sm:col-span-2">
              <button
                type="submit"
                className="relative flex self-center h-[50px] w-40 items-center rounded-lg border-2 border-lime !text-lime text-sm 2xl:text-lg 3xl:text-xl font-semibold justify-center overflow-hidden bg-transparent hover:!text-main-gray shadow-2xl transition-all before:absolute before:h-0 before:w-0 before:rounded-full before:bg-lime before:duration-500 before:ease-out hover:shadow-lime-lighter hover:before:h-56 hover:before:w-56"
              >
                <span className="relative z-10">{t("send")}</span>
              </button>

              <span className="text-sm 2xl:text-base text-gray-light">
                {t("formRequired")}
              </span>
            </div>
          </form>
          <div className=""></div>
        </div>
        <Footer2 />
      </section>
    </>
  );
}
