import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import type { FieldValues } from "react-hook-form";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import FormContact from "../../Home/Components/FormContact";
import { serverTimestamp } from "firebase/firestore";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../firebase";

const phoneRegex = new RegExp(
  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
);

const schema = z.object({
  firstName: z.string().nonempty({ message: 'emptyError'}),
  lastName: z.string().nonempty({ message: 'emptyError'}),
  email: z
    .string()
    .nonempty({ message: 'emptyError'})
    .email({ message: 'emailError'}),
  phone: z
    .string()
    .nonempty({ message: 'emptyError'})
    .regex(phoneRegex, { message: 'numberError'}),
  media: z.string(),
  portfolio: z.string(),
});

type TSchema = z.infer<typeof schema>;
type Props = {
  vacancy: string;
};

export const FormWork: React.FC<Props> = ({ vacancy }) => {
  const [send, isSend] = useState(false);
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    getValues,
  } = useForm<TSchema>({
    resolver: zodResolver(schema),
  });

  const onSubmit = async (data: FieldValues) => {
    const TOKEN = process.env.REACT_APP_TOKEN;
    const CHAT_ID = "-1002096593283";
    const URI_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;
    let message = `<b>Резюме</b>\n`;
    message += `<b>Вакансия: </b> ${vacancy}\n`;

    message += `<b>Имя: </b> ${getValues("firstName")} ${getValues(
      "lastName"
    )}\n`;
    message += `<b>Телефон: </b> ${getValues("phone")}\n`;
    message += `<b>Email: </b> ${getValues("email")}\n`;
    message += `<b>Соцсети: </b> ${getValues("media")}\n`;
    message += `<b>Ссылка на портфолио: </b> ${getValues("portfolio")}\n`;

    const clientsRef = collection(db, "job");

    const onSubmitFB = async () => {
      try {
        await addDoc(clientsRef, {
          date: serverTimestamp(),
          vacancy: vacancy,

          firstName: getValues("firstName"),
          lastName: getValues("lastName"),
          phone: getValues("phone"),
          email: getValues("email"),
          media: getValues("media"),
          portfolio: getValues("portfolio"),
        });
      } catch (err) {
        console.log(err);
      }
    };
    const Sender = async () => {
      try {
        await axios
          .post(URI_API, {
            chat_id: CHAT_ID,

            parse_mode: "html",

            text: message,
          })
          .then(({ data }) => {
            //   return data;
            isSend(true);
          });
      } catch (e) {
        console.log("Sending error", e);
      }
    };
    Sender();
    onSubmitFB();
    reset();
  };
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="self-center">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="m-auto grid max-w-screen-md gap-4 sm:grid-cols-2"
        >
          {send && (
            <>
              {" "}
              <div className="sm:col-span-2">
                <span className="text-sm 2xl:text-lg 3xl:text-xl text-lime">
                {t("successSend")}
                </span>
              </div>
            </>
          )}

          <div>
            <input
              {...register("firstName")}
              placeholder={t("formFirstName")}
              name="firstName"
              className="w-full bg-main-gray border-b-2 border-gray-800 px-3 py-2 outline-none transition duration-100 focus:border-lime"
            />
            {errors.firstName && (
                <p className="text-error pt-1">{t(`${errors.firstName.message}`)}</p>
              )}
          </div>

          <div className="">
            <input
              {...register("lastName")}
              placeholder={t("formLastName")}
              name="lastName"
              className="w-full bg-main-gray border-b-2 border-gray-800 px-3 py-2 outline-none transition duration-100 focus:border-lime "
            />
            {errors.lastName && (
                <p className="text-error pt-1">{t(`${errors.lastName.message}`)}</p>
              )}
          </div>

          <div className="sm:col-span-2">
            <input
              {...register("phone")}
              placeholder={t("formPhone")}
              name="phone"
              className="w-full bg-main-gray border-b-2 border-gray-800 px-3 py-2 outline-none transition duration-100 focus:border-lime"
            />
            {errors.phone && (
                <p className="text-error pt-1">{t(`${errors.phone.message}`)}</p>
              )}
          </div>

          <div className="sm:col-span-2">
            <input
              {...register("email")}
              placeholder="Email*"
              name="email"
              className="w-full bg-main-gray border-b-2 border-gray-800 px-3 py-2 outline-none transition duration-100 focus:border-lime focus:invalid:border-b focus:invalid:border-red"
            />
            {errors.email && (
                <p className="text-error pt-1">{t(`${errors.email.message}`)}</p>
              )}
          </div>

          <input
            disabled
            placeholder={vacancy}
            name="vacancy"
            value={vacancy}
            className="md:hidden w-full bg-main-gray border-b-2 border-gray-800 px-3 py-2 outline-none transition duration-100 focus:border-lime focus:invalid:border-b focus:invalid:border-red"
          />

          <div className="sm:col-span-2">
            <input
              {...register("media")}
              placeholder={t("formMedia")}
              name="media"
              className="w-full bg-main-gray border-b-2 border-gray-800 px-3 py-2 outline-none transition duration-100 focus:border-lime"
            />
          </div>

          <div className="sm:col-span-2">
            <input
              {...register("portfolio")}
              placeholder={t("formPortfolio")}
              name="portfolio"
              className="w-full bg-main-gray border-b-2 border-gray-800 px-3 py-2 outline-none transition duration-100 focus:border-lime"
            />
          </div>

          <div className="sm:col-span-2">
            <span className="text-sm 2xl:text-base text-gray-light">
              {t("personalData")}
            </span>
          </div>

          <div className="flex items-center justify-between sm:col-span-2">
            <button
              type="submit"
              className="relative flex self-center h-[50px] w-40 items-center rounded-lg border-2 border-lime !text-lime text-sm font-semibold justify-center overflow-hidden bg-transparent hover:!text-main-gray shadow-2xl transition-all before:absolute before:h-0 before:w-0 before:rounded-full before:bg-lime before:duration-500 before:ease-out hover:shadow-lime-lighter hover:before:h-56 hover:before:w-56"
            >
              <span className="relative z-10">{t("send")}</span>
            </button>

            <span className="text-sm text-gray-500">{t("formRequired")}</span>
          </div>
        </form>
      </div>
    </>
  );
};
