import type { FieldValues } from "react-hook-form";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { db, storage } from "../firebase";
import {
  Firestore,
  limit,
  limitToLast,
  orderBy,
  query,
  serverTimestamp,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import {
  ref,
  uploadBytes,
  listAll,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import { NavControl } from "./NavControl";
import { useAuth } from "../contexts/AuthContext2";
import i18n from "../i18n";
import { Project } from "../Pages/Projects/Components/Project";

const schema = z.object({
  file: z.any(),
  link: z
    .string()
    .nonempty("Поле не может быть пустым")
    .includes("/embed/", { message: "Нужна ссылка для вставки на сайт" }),
  name: z.string().nonempty("Поле не может быть пустым"),
  nameEN: z.string().nonempty("Поле не может быть пустым"),
  desc: z.string().nonempty("Поле не может быть пустым"),
  descEN: z.string().nonempty("Поле не может быть пустым"),
});

type TSchema = z.infer<typeof schema>;

export default function ProjectsControl() {
  const [url, setUrl] = React.useState<any>();
  const [data, setData] = React.useState<any>();
  const [deleted, isDeleted] = useState(false)
  const [showreel, setShowreel] = React.useState<any>();

  const [link, setLink] = React.useState<any>();
  const [name, setName] = React.useState<any>();
  const [downloadName, setDownloadName] = React.useState<any>();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const { user } = useAuth();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    getValues,
  } = useForm<TSchema>({
    resolver: zodResolver(schema),
  });
  const handleUpload = (e: any) => {
    // console.log(e.target.files[0]);
    setDownloadName(e.target.files[0].name);
    setLoading(true);
    const fileRef = ref(storage, `projects/${uuidv4()}`);
    uploadBytes(fileRef, e.target.files[0]).then((data) => {
      //   console.log(data, "файл");
      setName(data.metadata.name);
      // console.log(data.metadata.name)
      getDownloadURL(data.ref).then((val) => {
        setUrl(val);
        setLoading(false);
      });
    });
  };
  const onSubmit = async (data: FieldValues) => {
    const valRef = collection(db, "projects");
    console.log(link);
    try {
      await addDoc(valRef, {
        date: serverTimestamp(),
        url: url,
        name: getValues("name"),
        nameEN: getValues("nameEN"),
        youtube: getValues("link"),
        desc: getValues("desc"),
        descEN: getValues("descEN"),

        downloadName: downloadName,
      });
      console.log("Data added successfully");
    } catch (e) {
      console.log(e);
    }
    setAlert(true);
    reset();
    window.location.reload();
  };

  const [documentData, setDocumentData] = useState();
  const getData = async () => {
    const q1 = query(collection(db, "projects"), orderBy("date"));
    const dataDB = await getDocs(q1);
    const allData = dataDB.docs.map((val) => ({ id: val.id, ...val.data() }));

    setShowreel(allData);
  };

  const deleteItem = async (id: any, name: any) => {
    const docRef = doc(db, "projects", id);

    try {
      await deleteDoc(docRef);
      isDeleted(true)
      window.location.reload();
      console.log("Entire Document has been deleted successfully.");
    } catch (e) {
      console.log(e);
    }

    const desertRef = ref(storage, `projects/${name}`);

    // Delete the file
    deleteObject(desertRef)
      .then(() => {
        // File deleted successfully
        console.log("file deleted succesfully");
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
      });
  };

  useEffect(() => {
    getData();
  }, []);
  const [mouseOver, isMouseOver] = useState(false);

  const handleOnMouseOver = (e: React.MouseEvent<HTMLElement>) => {
    isMouseOver(true);
  };
  const handleOnMouseOut = (e: React.MouseEvent<HTMLElement>) =>
    isMouseOver(false);

  const linkClassName = errors.link ? "border-error" : "border-lime-lighter";
  const linkClasses = [
    "w-full  bg-main-gray border-b-2  px-3 py-2 outline-none focus:bg-main-gray transition duration-100 focus:border-lime",
    linkClassName,
  ];
  const fileClassName = errors.file ? "border-error" : "border-lime-lighter";
  const fileClasses = [
    "w-full  bg-main-gray border-b-2  px-3 py-2 outline-none focus:bg-main-gray transition duration-100 focus:border-lime",
    fileClassName,
  ];
  const nameClassName = errors.name ? "border-error" : "border-lime-lighter";
  const nameClasses = [
    "w-full  bg-main-gray border-b-2  px-3 py-2 outline-none focus:bg-main-gray transition duration-100 focus:border-lime",
    nameClassName,
  ];
  const nameENClassName = errors.nameEN
    ? "border-error"
    : "border-lime-lighter";
  const nameENClasses = [
    "w-full  bg-main-gray border-b-2  px-3 py-2 outline-none focus:bg-main-gray transition duration-100 focus:border-lime",
    nameENClassName,
  ];
  const descClassName = errors.desc ? "border-error" : "border-lime-lighter";
  const descClasses = [
    "w-full  bg-main-gray border-b-2  px-3 py-2 outline-none focus:bg-main-gray transition duration-100 focus:border-lime",
    descClassName,
  ];
  const descENClassName = errors.descEN
    ? "border-error"
    : "border-lime-lighter";
  const descENClasses = [
    "w-full  bg-main-gray border-b-2  px-3 py-2 outline-none focus:bg-main-gray transition duration-100 focus:border-lime",
    descENClassName,
  ];

  return (
    <>
      {user && (
        <>
          <NavControl />

          <section
            id="form"
            className="pb-10 pt-[108px] 3xl:pb-16 3xl:pt-[132px] border-gray-light bg-main-gray z-40 relative text-white"
          >
            <h3 className="px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 text-3xl 2xl:text-4xl 3xl:text-5xl font-bold  pb-10 3xl:pb-16">
              ДОБАВИТЬ ПРОЕКТЫ
            </h3>

            <div className="px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 flex flex-col justify-between gap-16">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="2xl:text-lg 3xl:text-xl max-w-screen-sm gap-5"
              >
                {alert ? (
                  <span>Загружено</span>
                ) : (
                  // <span>Проверить заполненность полей</span>
                  <></>
                )}
                <div className="mb-3">
                  <input
                    // className="block w-1/2 text-lg mb-10 text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    className={fileClasses.join(" ")}
                    id="file_input"
                    type="file"
                    {...register("file")}
                    // onChange={onChangeHandler}
                    onChange={(e) => handleUpload(e)}
                  ></input>
                  {loading ? (
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    // <h1>Документ успешно загружен</h1>
                    <></>
                  )}
                  {errors.file && (
                    <p className="text-error pt-1">{`${errors.file.message}`}</p>
                  )}
                </div>
                <div className="mb-3">
                  <input
                    {...register("name")}
                    placeholder="Название"
                    name="name"
                    className={nameClasses.join(" ")}
                  />
                  {errors.name && (
                    <p className="text-error pt-1">{`${errors.name.message}`}</p>
                  )}
                </div>
                <div className="mb-3">
                  <input
                    {...register("nameEN")}
                    placeholder="Название на аннглийском"
                    name="nameEN"
                    className={nameClasses.join(" ")}
                  />
                  {errors.nameEN && (
                    <p className="text-error pt-1">{`${errors.nameEN.message}`}</p>
                  )}
                </div>
                <div className="mb-3">
                  <input
                    {...register("desc")}
                    placeholder="Описание"
                    name="desc"
                    className={descClasses.join(" ")}
                  />
                  {errors.desc && (
                    <p className="text-error pt-1">{`${errors.desc.message}`}</p>
                  )}
                </div>
                <div className="mb-3">
                  <input
                    {...register("descEN")}
                    placeholder="Описание на английском"
                    name="descEN"
                    className={descENClasses.join(" ")}
                  />
                  {errors.descEN && (
                    <p className="text-error pt-1">{`${errors.descEN.message}`}</p>
                  )}
                </div>
                <div className="mb-3">
                  <input
                    {...register("link")}
                    placeholder="Ссылка на youtube*"
                    name="link"
                    className={linkClasses.join(" ")}
                  />
                  {errors.link && (
                    <p className="text-error pt-1">{`${errors.link.message}`}</p>
                  )}
                </div>

                <div className="flex items-center mt-5 justify-between sm:col-span-2">
                  {loading ? (
                    <button
                      type="submit"
                      className="relative flex self-center cursor-not-allowed opacity-50  h-[50px] w-40 items-center rounded-lg border-2 border-lime !text-lime text-sm 2xl:text-lg 3xl:text-xl font-semibold justify-center overflow-hidden bg-transparent hover:!text-main-gray shadow-2xl transition-all before:absolute before:h-0 before:w-0 before:rounded-full before:bg-lime before:duration-500 before:ease-out hover:shadow-lime-lighter hover:before:h-56 hover:before:w-56"
                    >
                      <span className="relative z-10">Загрузить</span>
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="relative flex self-center  h-[50px] w-40 items-center rounded-lg border-2 border-lime !text-lime text-sm 2xl:text-lg 3xl:text-xl font-semibold justify-center overflow-hidden bg-transparent hover:!text-main-gray shadow-2xl transition-all before:absolute before:h-0 before:w-0 before:rounded-full before:bg-lime before:duration-500 before:ease-out hover:shadow-lime-lighter hover:before:h-56 hover:before:w-56"
                    >
                      <span className="relative z-10">Загрузить</span>
                    </button>
                  )}
                </div>
              </form>
            </div>
          </section>

          <section
            id="projects"
            className="px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 pb-10 pt-[108px] 3xl:pb-16 3xl:pt-[132px] border-gray-light bg-main-gray z-40 relative text-white"
          >
            <div>
              <h3 className="pb-10 3xl:pb-10 text-3xl 2xl:text-4xl 3xl:text-5xl font-bold">
                ПРОЕКТЫ
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 xl:!grid-rows-2 xl:overflow-hidden 3xl:grid-cols-4 gap-5">
            {/* {res} */}
            {/* {details && <>{res2}</>} */}
            {showreel !== undefined &&
              showreel !== null &&
              showreel.map((value: any) => {
                return (
                  <>
                    {i18n.language == "ru" ? (
                      <>
                        <Project
                          name={value.name}
                          video={value.url}
                          link={value.youtube}
                          typeOf={value.desc}
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <Project
                          name={value.nameEN}
                          video={value.url}
                          link={value.youtube}
                          typeOf={value.descEN}
                        />
                      </>
                    )}
                  </>
                );
              })}
          </div>

              <div className="flex justify-center py-8 col-span-3"></div>
            </div>
          </section>

          <section className="bg-main-gray  !z-40 relative text-white border-b border-lime-lighter py-10 3xl:py-16">
            <h3 className="px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 text-3xl 2xl:text-4xl 3xl:text-5xl font-bold  pb-10 3xl:pb-16">
              ИСТОРИЯ
            </h3>
            {deleted && (<>
            <span className="px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 font-bold text-lime">УДАЛЕНО</span>
            </>)}
            <div className=" px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-white uppercase bg-lime-lighter/10">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Название видео
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Ссылка на видео
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Ссылка на youtube
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Дата создания
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Действие
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {showreel !== undefined &&
                    showreel !== null &&
                    showreel.map((value: any) => {
                      return (
                        <tr className="odd:bg-white odd:dark:bg-transparent even:bg-lime-lighter/10 even:dark:bg-lime-lighter/10 border-b dark:border-gray-700">
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {value.downloadName}
                          </th>
                          <td className="px-6 py-4">
                            {/* {value.url} */}
                            <button
                              // type="submit"
                              // onClick={(e:any) => deleteItem(value.id, value.name)}
                              className="relative flex self-center h-[25px] w-24 items-center rounded-lg border-2 border-lime !text-lime text-sm 2xl:text-lg font-semibold justify-center overflow-hidden bg-transparent hover:!text-main-gray shadow-2xl transition-all before:absolute before:h-0 before:w-0 before:rounded-full before:bg-lime before:duration-500 before:ease-out hover:shadow-lime-lighter hover:before:h-56 hover:before:w-56"
                            >
                              <a
                                className="relative z-10 py-2"
                                href={value.url}
                                target="_blank"
                              >
                                Просмотр
                              </a>
                            </button>
                          </td>
                          <td className="px-6 py-4">
                            {/* {value.ybLink} */}
                            <button
                              // type="submit"
                              // onClick={(e:any) => deleteItem(value.id, value.name)}
                              className="relative flex self-center h-[25px] w-24 items-center rounded-lg border-2 border-lime !text-lime text-sm 2xl:text-lg font-semibold justify-center overflow-hidden bg-transparent hover:!text-main-gray shadow-2xl transition-all before:absolute before:h-0 before:w-0 before:rounded-full before:bg-lime before:duration-500 before:ease-out hover:shadow-lime-lighter hover:before:h-56 hover:before:w-56"
                            >
                              <a
                                className="relative z-10 py-2"
                                href={value.ybLink}
                                target="_blank"
                              >
                                Просмотр
                              </a>
                            </button>
                          </td>
                          <td className="px-6 py-4">
                            {value.date.toDate().toDateString()}
                          </td>
                          <td className="px-6 py-4">
                            {/* <a href="#" onClick={(e:any) => deleteItem(value.id, value.name)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a> */}
                            <button
                              // type="submit"
                              onClick={(e: any) =>
                                deleteItem(value.id, value.name)
                              }
                              className="relative flex self-center h-[25px] w-24 items-center rounded-lg border-2 border-lime !text-lime text-sm 2xl:text-lg font-semibold justify-center overflow-hidden bg-transparent hover:!text-main-gray shadow-2xl transition-all before:absolute before:h-0 before:w-0 before:rounded-full before:bg-lime before:duration-500 before:ease-out hover:shadow-lime-lighter hover:before:h-56 hover:before:w-56"
                            >
                              <span className="relative z-10 py-2">
                                Удалить
                              </span>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </section>
        </>
      )}
    </>
  );
}
