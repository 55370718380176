import React, { useEffect, useState } from "react";
import { Project } from "./Components/Project";
import video1 from "../../Media/video1.mp4";
import video2 from "../../Media/video2.mp4";
import video5 from "../../Media/video5.mp4";
import video4 from "../../Media/video4.mp4";
import { Header } from "../Header";
import ShowreelMain from "./Components/ShowreelMain";
import Footer2 from "../Footer2";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import {
  SCREEN_SM,
  SCREEN_MD,
  SCREEN_LG,
  SCREEN_XL,
  SCREEN_XXL,
} from "./Components/breakpoints";
import { db } from "../../firebase";
import { useTranslation } from "react-i18next";

export default function Projects() {
  const { t, i18n, ready } = useTranslation();

  const projects = [
    {
      id: 1,
      name: "Пушкин",
      bg: video2,
      type: "Мультсериал",
      link: "https://www.youtube.com/embed/BCF1Y8FWIUU?si=nf-Rm7ykJsUbA_5n",
    },
    {
      id: 2,
      name: "Соня и Лёня",
      bg: video5,
      type: "Мультсериал",
      link: "https://www.youtube.com/embed/7jQnLWc2G9E?si=wOIpeVS7HOmyFONA",
    },
  ];

  // const projects2 = [
  //   {
  //     id: 7,
  //     name: "Ekrani_oformlenie",
  //     bg: video1,
  //     type: "dd",
  //     link: "https://www.youtube.com/embed/bov_Vw2S7UM",
  //   },
  //   {
  //     id: 8,
  //     name: "Крутиксы",
  //     bg: video2,
  //     type: "dd",
  //     link: "https://www.youtube.com/embed/30TKDJL-pEY",
  //   },
  // ];

  const [details, setDetails] = useState(false);

  const res = projects.map((obj) => {
    return (
      <Project
        name={obj.name}
        video={obj.bg}
        link={obj.link}
        typeOf={obj.type}
      />
    );
  });

  // const res2 = projects2.map((obj) => {
  //   return (
  //     <Project
  //       name={obj.name}
  //       video={obj.bg}
  //       link={obj.link}
  //       typeOf={obj.type}
  //     />
  //   );
  // });
  const [showreel, setShowreel] = React.useState<any>();

  const getData = async () => {
    const valRef = collection(db, "projects");
    const dataDB = await getDocs(valRef);
    const allData = dataDB.docs.map((val) => ({ id: val.id, ...val.data() }));

    setShowreel(allData);
  };
  useEffect(() => {
    getData();
  }, []);
  console.log(i18n.language);
  return (
    <>
      <Header />
      <section
        id="projects"
        className="px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 pb-10 pt-[108px] 3xl:pb-16 3xl:pt-[132px] border-gray-light bg-main-gray z-40 relative text-white"
      >
        <div>
          <h3 className="pb-10 3xl:pb-10 text-3xl 2xl:text-4xl 3xl:text-5xl font-bold">
            {t("mainProjects")}
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 xl:!grid-rows-2 xl:overflow-hidden 3xl:grid-cols-4 gap-5">
            {/* {res} */}
            {/* {details && <>{res2}</>} */}
            {showreel !== undefined &&
              showreel !== null &&
              showreel.map((value: any) => {
                return (
                  <>
                    {i18n.language == "ru" ? (
                      <>
                        <Project
                          name={value.name}
                          video={value.url}
                          link={value.youtube}
                          typeOf={value.desc}
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <Project
                          name={value.nameEN}
                          video={value.url}
                          link={value.youtube}
                          typeOf={value.descEN}
                        />
                      </>
                    )}
                  </>
                );
              })}
          </div>
          <div className="flex justify-center py-8 col-span-3">
            {/* <button
              onClick={() => setDetails((prev) => !prev)}
              className="relative self-center flex self-center h-[50px] p-5 3xl:w-auto items-center rounded-lg border-2 border-lime !text-lime text-sm 2xl:text-lg 3xl:text-xl font-semibold justify-center overflow-hidden bg-transparent hover:!text-main-gray shadow-2xl transition-all before:absolute before:h-0 before:w-0 before:rounded-full before:bg-lime before:duration-500 before:ease-out hover:shadow-lime-lighter hover:before:h-56 hover:before:w-56"
            >
              {details ? (
                <span className="relative z-10">Скрыть</span>
              ) : (
                <span className="relative z-10">Смотреть еще</span>
              )}
            </button> */}
          </div>
        </div>
      </section>
      <Footer2 />
    </>
  );
}
